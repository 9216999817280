import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import UserManagement from "@/views/UserManagement.vue";
const routes = [
  {
    path: "/",
    name: "home",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "lead" */ "../views/HomeView.vue"),
  },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/LoginView.vue"),
  },
  {
    path: "/lead_details/:lead_id",
    name: "LeadDetails",
    props: true,
    component: () => import("../views/LeadDetails.vue"),
  },
  {
    path: "/upload",
    name: "Upload",
    props: true,
    component: () => import("../views/UploadView.vue"),
  },
  {
    path: "/lead",
    name: "Lead",
    props: true,
    component: () => import("../views/LeadManagement.vue"),
  },
  {
    path: "/leads",
    name: "Leads",
    props: true,
    component: () => import("../views/LeadsPage.vue"),
  },
  {
    path: "/deals",
    name: "Deals",
    props: true,
    component: () => import("../views/DealsView.vue"),
  },
  {
    path: "/users",
    name: "User",
    component: UserManagement,
    props: true,
    // component: () => import("../views/UserManagement.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    props: true,
    component: () => import("../views/PaymentsView.vue"),
  },
  {
    path: "/communications",
    name: "Communications",
    props: true,
    component: () => import("../views/CommunicationsView.vue"),
  },
  {
    path: "/reports",
    name: "Report",
    props: true,
    component: () => import("../views/ReportView.vue"),
  },
  {
    path: "/help",
    name: "Help",
    props: true,
    component: () => import("../views/HelpView.vue"),
  },
  {
    path: "/api-documentation",
    name: "API",
    props: true,
    component: () => import("../views/ApiDocs.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    props: true,
    component: () => import("../views/SettingsView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
