<template>
  <div class="w-full h-screen overflow-hidden">
    <!-- navbar -->
    <Header></Header>
    <!-- navbar -->

    <div class="flex">
      <!-- left navbar -->
      <LeftNav></LeftNav>
      <!-- left navbar -->
      <div class="flex-1 flex flex-col h-full">
        <div class="w-full px-0 py-0">
          <div class="flex items-center justify-between px-6 py-3 shadow-lg">
            <div class="pr-6">
              <h1 class="text-[#000d2b] text-xl font-bold">Manage Users</h1>
            </div>

            <div class="flex items-center gap-10">
              <div class="flex hidden">
                <select
                  name=""
                  @change="activeCheck"
                  id="status"
                  class="flex items-center gap-2 min-w-[80px] px-6 py-2 justify-center text-sm font-bold bg-[#000d2b] text-white border-[#e5e7eb] rounded shadow-md"
                >
                  <option selected value="0">Active</option>
                  <option value="1">In-Active</option>
                </select>
              </div>
              <div
                class="flex"
                data-te-toggle="modal"
                data-te-target="#exampleModalScrollable"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                <button
                  class="flex items-center gap-2 min-w-[80px] px-6 py-1.5 justify-center text-sm font-normal bg-[#000d2b] text-white border-green-800 rounded shadow-md"
                >
                  <span class="">+</span>
                  <span class=""> Create </span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="flex flex-col gap-6 bg-[#f2f3f4] h-[calc(100vh-105px)] overflow-auto"
          >
            <div class="px-6">
              <div v-show="activeTab == 0">
                <table class="bg-white w-full">
                  <thead class="bg-[#000d2b] border-b sticky top-0 text-white">
                    <tr>
                      <th class="text-base text-left font-medium px-4 py-2">
                        S.No
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Name
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Email ID
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Phone Number
                      </th>

                      <th class="text-base text-left font-medium px-4 py-2">
                        Roles
                      </th>
                      <th class="text-base text-left font-medium px-4 py-2">
                        Joined Date
                      </th>

                      <th class="text-base text-left font-medium px-4 py-2">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="border-b"
                      v-for="(user, id) in user_details"
                      :key="id"
                    >
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ id + 1 }}
                      </td>

                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap flex gap-3"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'user']"
                          class="text-indigo-400"
                        />
                        {{ user.display_name }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'paper-plane']"
                          class="text-gray-400 px-2"
                        />
                        {{ user.email }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap flex gap-3"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'phone']"
                          class="text-green-500"
                        />
                        {{ user.phone_number }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ user.role }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                      >
                        {{ user.date_joined }}
                      </td>
                      <td
                        class="text-sm text-gray-700 text-left font-normal px-6 py-3 whitespace-nowrap"
                        :class="[
                          user.is_active == true
                            ? 'text-green-400'
                            : 'text-red-400',
                        ]"
                      >
                        {{ user.is_active == true ? "Active" : "In-Active" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    data-te-modal-init
    class="fixed left-0 top-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
    id="exampleModalScrollable"
    tabindex="-1"
    aria-labelledby="exampleModalScrollableLabel"
    aria-hidden="true"
  >
    <div
      data-te-modal-dialog-ref
      class="pointer-events-none relative h-[calc(100%-1rem)] w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px]"
    >
      <div
        class="pointer-events-auto relative flex max-h-[100%] w-full flex-col overflow-hidden rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600"
      >
        <div
          class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50"
        >
          <!--Modal title-->
          <h5
            class="font-medium leading-normal text-neutral-800 dark:text-neutral-200"
            id="exampleModalScrollableLabel"
          >
            Create New User
          </h5>
          <!--Close button-->
          <button
            type="button"
            class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
            data-te-modal-dismiss
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!--Modal body-->
        <div class="relative overflow-y-auto p-4">
          <div>
            <form action="" class="text-start flex flex-col gap-4">
              <div class="w-full flex flex-col gap-2 justify-start">
                <label for="">Display Name</label>
                <input
                  type="text"
                  name=""
                  id=""
                  class="h-8 border border-gray-300 rounded-md px-2"
                  required
                />
              </div>
              <div class="w-full flex flex-col gap-2 justify-start">
                <label for="">Email</label>
                <input
                  type="email"
                  name=""
                  id=""
                  required
                  class="h-8 border border-gray-300 rounded-md px-2"
                />
              </div>

              <div class="w-full flex flex-col gap-2 justify-start">
                <label for="">Phone</label>
                <input
                  type="phone"
                  name=""
                  id=""
                  required
                  class="h-8 border border-gray-300 rounded-md px-2"
                />
              </div>

              <div class="w-full flex flex-col gap-2 justify-start">
                <label for="">Role</label>
                <select
                  name=""
                  id=""
                  required
                  class="h-8 border border-gray-300 rounded-md px-2"
                >
                  <option value="">Select Role</option>
                  <option value="">Super Admin</option>
                  <option value="">Admin</option>
                  <option value="">Platform admin</option>
                  <option value="">Counsellor</option>
                </select>
              </div>
            </form>
          </div>
        </div>

        <!--Modal footer-->
        <div
          class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50"
        >
          <button
            type="button"
            class="py-1 px-2 bg-[#000d2b] text-white rounded-md"
            data-te-ripple-init
            data-te-ripple-color="light"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/Header.vue";
import LeftNav from "@/components/LeftNav.vue";
import { Modal, Ripple, initTE } from "tw-elements";

export default {
  name: "UserManagement",

  components: { LeftNav, Header },
  created() {
    axios
      .get(
        `${process.env.VUE_APP_API}/user/all/detail/?Key=0f63d17c63c02412f59b2a920b26122d76cd3ed2`
      )
      .then((response) => {
        console.log(response.data);
        this.user_details = response.data.data;
      });
  },

  data() {
    return {
      user_details: [],
      activeTab: 0,
      dataSelect: true,
    };
  },
  mounted() {
    initTE({ Modal, Ripple });
  },
  methods: {
    activeCheck(el) {
      let active = el.target.value;
      console.log(active);
      this.activeTab = active;
      console.log(this.activeTab);
    },
    selectCheckbox(event) {
      console.log(event.target.checked);
      if (event.target.checked) {
        this.dataSelect = true;
      } else {
        this.dataSelect = true;
      }
    },
  },
};
</script>

<style scoped>
label::after {
  content: "*";
  color: red;
}
</style>
