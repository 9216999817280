import { createStore } from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
    userInfo: null,
    isAuthenticated: false,
  },
  getters: {
    userInfo: (state) => state.userInfo,
    isAuthenticated: (state) => state.isAuthenticated,
  },
  actions: {
    async loginUser({ commit }, credentials) {
      await axios
        .post(`${process.env.VUE_APP_API}/login/`, credentials)
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == 200) {
            commit("setUserInfo", response.data);
          } else {
            console.log("login failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mutations: {
    setUserInfo: (state, data) => {
      console.log(data);
      state.userInfo = data;
      state.isAuthenticated = true;
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
